$(document).ready(function () {
    dReady();
    pageInit();

    // this is from header.php
    $(document).on("click", "#sidebar .menu-item", function (event) {
        event.stopPropagation();
        if (!$(event.target).is("a")) {
            var item_ref = $(this);
            var selected = ($(this).hasClass("expand")) ? null : this;
            $(this).addClass("expand").children().not("span").not("i").slideDown().parent().parent().children().not(selected).removeClass("expand").children().not("span").not("i").slideUp();

            if ($(item_ref).parent().parent().hasClass("alt")) {
                $(document).on("click", function (e) {
                    $(item_ref).removeClass("expand").children().not("span").not("i").slideUp();
                });
            }
        }
    });

    $(document).on("click", function (e) {
        /**
         * PAGINATION CONTAINER HANDLER
         */
        if (!$(e.target).is(".v-pop-container") && !$(e.target).is(".v-pop") && !$(e.target).is("#pg")) {
            $("div.v-pop-container").remove();
            $("a.goto").removeClass("v__goto--active");
        }
    });
});

window.pageInit = function pageInit() {
    $(".reseller__table--title").each(function () {
        $(this).text(truncate($(this).text(), 22));
    });

    $(".contact--text").each(function () {
        $(this).text(truncate($(this).text(), 24));
    });

    $(".forwarder__domain--text").each(function () {
        $(this).text(truncate($(this).text(), 28));
    });

    $(".hostname-ref").each(function () {
        $(this).text(truncate($(this).text(), 36));
    });

    $(".recurringDesc").each(function () {
        let text = $(this).text();
        if (text.length > 50) {
            $(this).append("<div class=\"i\" info=\"" + text + "\"><i class=\"material-icons info-icon icon_brick_error\"></i></div>");
        }
        $(this).text(truncate(text, 50));
    });
}

window.dReady = function dReady() {
    var expanding = false;
    var tds = $("thead:first tr:first td").length;

    $("table .expand_actions:eq(0), table .expand_actions_ajax:eq(0)").parent().parent().parent().css("cursor", "pointer");
    $(".comeback").on("click", function () {
        if ($("input[name=s]").css("opacity") == "1") {
            $("#search").trigger("submit");
        }
    });
    $(".searchIcon").on("click", function () {
        $("#search").trigger("submit");
    });

    if ($().fancybox) {
        $("a.box").fancybox();
    }

    if ($("#alerts").children().length > 0) {
        $("main").addClass("alerts");
        $("#alerts").fadeIn();
    } else {
        $("main").removeClass("alerts");
        $("#alerts").hide();
    }

    $(".dropdown").on("click touch", function () {
        $(this).trigger("mouseover");
    });

    if (!$("#filter_type").length) {
        $("#page_info").addClass("full-width");
    }

    $("#delete-note").on("click", function (e) {
        e.preventDefault();
        $(this).siblings("a").trigger("click");
    });

    $("#minimize-alert").on("click", function (e) {
        e.preventDefault();
        parent = $(this).parent();
        if (parent.css("max-height") != "60px") {
            parent.css("max-height", "60px").css("overflow", "hidden");
        } else {
            parent.css("max-height", "").css("overflow", "");
        }
    });

    $(".close-alert").on("click", function (e) {
        e.preventDefault();
        $(this).parent().slideUp(function() {
            $(this).remove();
        });
    });

    $(".dismiss-alert").on("click", function (e) {
        e.preventDefault();
        $(this).parent().fadeOut();
    });

    if ($("#pageDropdown").length) {
        var ajax_type = $(".dropdown-scroll").attr("ajax-type");
        var skel_data = $("#uniq").val();

        $.get("/home/ajax", {
                "data": skel_data,
                "type": ajax_type
            }, function (data) {

                $("#domain-option-list").append(data);
            }
        );
    }
    ;

    $(".expand_actions").parent().off("click");
    $(".expand_actions_ajax").parent().off("click");

    $(".expand_actions").parent().on("click", function () {
        var existing = $("div:eq(0)", $(this).next());
        if (existing.length) {
            $(this).removeClass("expanded");
            existing.slideUp(function () {
                existing.parent().parent().remove();
            });
        } else {
            tds = $("thead:first tr:first td", $(this).parent().parent()).length;
            $(this).addClass("expanded");
            $(".drop_skeleton .act_padd > div").append("<div style=\"clear:both;\"></div>");
            var skel_html = $(".drop_skeleton").html();
            var skel_data = $(".uniq", $(this)).val().split("|");
            for (let i = 0; i <= skel_data.length - 1; i++) {
                var re = new RegExp("%UNIQ" + (i + 1).toString() + "%", "g");
                skel_html = skel_html.replace(re, skel_data[i]);
            }
            $(this).after("<tr class=\"actions_\"><td colspan=\"" + tds + "\"><div style=\"display:none;\" class=\"action_spawn\">" + skel_html + "</div></td></tr>");
            var new_div = $("div", $(this).next());
            if ($(this).hasClass("z")) {
                new_div.addClass("z");
            }
            new_div.slideDown();
        }
    });

    $('.expand_actions_ajax').parent().on('click', function (event) {
        if ($(event.target).is('a')) {
            return;
        }

        if (expanding) {
            return;
        }

        expanding = true;
        const row = $(this);
        const existing = $("div:eq(0)", $(this).next());

        if ($(this).next().hasClass("actions_")) {
            row.removeClass("expanded");
            existing.slideUp(function () {
                existing.parent().parent().remove();
                expanding = false;
            });
        } else {
            tds = $("thead:first tr:first td", $(this).parent().parent()).length;
            $(row).fadeTo("fast", 1);
            $(this).addClass("expanded");
            const ajax_type = $(".expand_actions_ajax", row).attr("ajax-type");
            const skel_data = $(".uniq", row).val().split("|");
            $.get("/home/ajax", {
                "data": skel_data[0],
                "type": ajax_type
            }, function (data) {
                row.after("<tr class=\"actions_\"><td colspan=\"" + tds + "\"><div style=\"display:none;\" class=\"action_spawn\">" + data + "</div></td></tr>");
                const new_div = $("div", row.next());
                if (row.hasClass("z")) {
                    new_div.parent().addClass("z");
                }

                new_div
                    .parent()
                    .slideDown(500, function () {
                        expanding = false;
                    });

                new_div
                    .find('select')
                    .chosen();

                $(".dropdown_options_types .links a", new_div).on("click",
                    function () {
                        $(".dropdown_options_links div", new_div).stop(true, true);
                        $(".linksSelector > div > div", new_div).stop(true);
                        const dType = $(this).attr("data-type");
                        const elm = $(".dropdown_options_types .links a.selected", new_div);

                        if (elm.attr("data-type") != dType) {
                            elm.removeClass("selected");


                            $(".dropdown_options_links div[data-type=" + elm.attr("data-type") + "]", new_div).stop().fadeOut("fast", function () {
                                $(".dropdown_options_links div[data-type=" + dType + "]", new_div).fadeIn("fast");
                            });

                            const childIndex = ($(this).index() + 1);
                            $(".linksSelector > div > div", new_div).animate({
                                marginLeft: (20 * childIndex).toString() + "%"
                            }, 500);


                            $(this).addClass("selected");
                        }

                        return false;
                    }
                );

                $(row).fadeTo("fast", 1);
            });
        }
    });

    if ($(".quickOption").length > 0) {
        $("main").prepend("<div class=\"info_options caret purple\" style=\"display:none;\"></div>");
        $(".quickOption").each(function () {
            $(this).on("mousemove", function (e) {
                let text = $(this).attr("info").replace(/<[^>]*>?/gm, "");
                let bubble_top = e.pageY - $(".info_options").outerHeight();

                $(".info_options")
                .css("left", e.pageX - 45)
                .css("top", bubble_top - 10)
                .css("position", "absolute")
                .css("z-index", "99993");

                if (!$(".info_options").is(":visible")) {
                    $(".info_options").html(text).fadeIn("fast");
                }
                $(this).mouseout(function () {
                    $(".info_options").stop(true, true, true).fadeOut("fast");
                });
            });
        });
    }

    if ($(".sidenav__item--xs").length > 0) {
        $("main").prepend("<div class=\"info_options\" style=\"display:none;\"></div>");
        $(".sidenav__item--xs").each(function () {
            $(this).on("mousemove", function (e) {
                var h = e.pageY - $(".info_options").height() / 2;
                $(".info_options").html("<div class=\"caret--left purple\"></div><div style=\"position:absolute;top:40%;\"><div style=\"position:relative;right: 0px;bottom:4px;margin-bottom:0;\"></div></div>" + $(this).attr("info")).fadeIn("fast");
                $(".info_options").css("left", e.pageX + 25).css("top", h).css("position", "absolute").css("z-index", "99993");
                $(this).mouseout(function () {
                    $(".info_options").stop(true, true, true).fadeOut("fast");
                });
            });
        });
    }

    $("td[url=true]").parent().on("mousedown", function (b) {
        if (b.which == 2) {
            window.open("http://" + $("td:eq(0)", this).text());
        }
    });

    if ($(".cc-img-icon").length > 0) {
        $("main").prepend("<div class=\"info_popout\" style=\"display:none;\"></div>");
        $(".cc-img-icon").each(function () {
            $(this).on("mousemove", function (e) {
                var h = e.pageY - $(".info_popout").height() / 2;
                $(".info_popout").html("<div style=\"position:absolute;top:40%;width:20%;\"><div style=\"position:relative;right: 22px;bottom:4px;margin-bottom:0;\"></div></div>" + $(this).attr("info")).fadeIn("fast");
                $(".info_popout").css("left", e.pageX + 26).css("top", h).css("position", "absolute").css("z-index", "99993");
                $(this).mouseout(function () {
                    $(".info_popout").stop(true, true, true).fadeOut("fast");
                });
            });
        });
    }

    if ($(".i[info]").length > 0) {
        $("main").prepend("<div class=\"info_popout\" style=\"display:none;\"></div>");

        // Remove existing handlers before attaching this.
        $("main").off("mousemove", ".i[info]")
        $("main").on("mousemove", ".i[info]", function (e) {
            var h = e.pageY - $(".info_popout").height() / 2;
            $(".info_popout").html("<div style=\"position:absolute;top:40%;\"><div style=\"position:relative;right: 22px;bottom:4px;margin-bottom:0;\"></div></div>" + $(this).attr("info")).fadeIn("fast");
            $(".info_popout").css("left", e.pageX + 26).css("top", h).css("position", "absolute").css("z-index", "99993");
            $(this).mouseout(function () {
                $(".info_popout").stop(true, true, true).fadeOut("fast");
            });
        });
    }

    if ($(".table-icon").length > 0) {
        $("main").prepend("<div class=\"icon_popout\" style=\"display:none;\"></div>");
        $(".table-icon").each(function () {
            $(this).on("mousemove", function (e) {
                var w = e.pageX - $(".icon_popout").width() / 2;
                $(".icon_popout").html("<div style=\"position:absolute;top:40%;\"><div style=\"position:relative;right: 22px;bottom:4px;margin-bottom:0;\"></div></div>" + $(this).attr("info")).fadeIn("fast");
                $(".icon_popout").css("top", e.pageY + 25).css("left", w).css("position", "absolute").css("z-index", "99993");
                $(this).mouseout(function () {
                    $(".icon_popout").stop(true, true, true).fadeOut("fast");
                });
            });
        });
    }

    $(".goto").on("click", function (event) {
        event.stopPropagation();
        if (!$("div.v-pop-container").length) {
            var pop = $("<div class='v-pop-container'>").append(
                "<div class=\"v-pop\">" +
                "<input type=\"text\" class=\"v-input\" placeholder=\"Enter a page number\" id=\"pg\"/>" +
                "<a id=\"pgGo\">Go</a>" +
                "</div>"
            );

            $(this).addClass("v__goto--active");
            $(this).before(pop);
            document.getElementById("pg").focus();

            if (window.location.href.indexOf("id=") === -1) {
                $("#pgGo").on("click", function (event) {
                    event.stopPropagation();
                    let url = new URLSearchParams(window.location.search);

                    let counter = $("#paginator-counter");
                    let min = parseInt(counter.attr("data-min-page"));
                    let max = parseInt(counter.attr("data-max-page"));
                    let page = parseInt(document.getElementById("pg").value);

                    if (page >= min && page <= max) {
                        url.set("page", document.getElementById("pg").value);

                        // We have the headers to fake a boost and save history
                        htmx.ajax("GET", "?" + url.toString(), {
                            headers: {
                                "HX-Boosted": true,
                                "HX-Fake-Boost": true
                            }
                        });
                    }
                });

                $("#pg").on("keypress", function (event) {
                    event.stopPropagation();
                    if ((event.keyCode) == 13) {
                        let url = new URLSearchParams(window.location.search);

                        let counter = $("#paginator-counter");
                        let min = parseInt(counter.attr("data-min-page"));
                        let max = parseInt(counter.attr("data-max-page"));
                        let page = parseInt(document.getElementById("pg").value);

                        if (page >= min && page <= max) {
                            url.set("page", document.getElementById("pg").value);

                            // We have the headers to fake a boost and save history
                            htmx.ajax("GET", "?" + url.toString(), {
                                headers: {
                                    "HX-Boosted": true,
                                    "HX-Fake-Boost": true
                                }
                            });
                        }
                    }
                });
            } else {
                var getQueryString = function (field, url) {
                    var href = url ? url : window.location.href;
                    var reg = new RegExp("[?&]" + field + "=([^&#]*)", "i");
                    var string = reg.exec(href);
                    return string ? string[1] : null;
                };

                var id = getQueryString("id");

                $("#pgGo").on("click", function (event) {
                    event.stopPropagation();
                    $(this).attr("href", "?page=" + document.getElementById("pg").value + "&id=" + id);
                });

                $("#pg").on("keypress", function (event) {
                    event.stopPropagation();
                    if ((event.keyCode) == 13) {
                        // var newLocation = location.hostname + location.pathname + '?page=' + document.getElementById("pg").value;
                        document.location.replace("?page=" + document.getElementById("pg").value + "&id=" + id);
                    }
                });
            }
        } else {
            $("div.v-pop-container").remove();
            $(this).removeClass("v__goto--active");
        }
    });

    $("tr:visible:odd").addClass("z");
    $("tr:hidden:odd").addClass("z");
    $(".menu li:has(ul)").on("click", function (event) {
        if (this == event.target) {
            $(this).toggleClass("clicked").children("ul").slideToggle();
            $(this).find("li:has(ul)").removeClass("clicked").find("ul").slideUp();
            $(this).siblings().not(".current_item").removeClass("clicked").find("ul").slideUp();
        }
    }).addClass("has_ul");
    $(".menu-item.admin-item").on("click", function () {
        //load_counters('.'+$('span', this).attr('class'));

        if (jQuery(this).find(".menu-admin-domainnames").hasClass("menu-admin-domainnames")) {
            load_counters(".menu-admin-domainnames");
        } else if (jQuery(this).find(".menu-admin-resellers").hasClass("menu-admin-resellers")) {
            load_counters(".menu-admin-resellers");
        }
    });
    if ($(".menu-admin-domainnames").parent().hasClass("expand")) {
        load_counters(".menu-admin-domainnames");
    }
    if ($(".menu-admin-resellers").parent().hasClass("expand")) {
        load_counters(".menu-admin-resellers");
    }

    $(".ipv4.add").on("click", function () {
        var markup =
            "<tr class=\"ipv4\">" +
            "<td><strong class=\"v-table-input-label\">IPv4 Address</strong></td>" +
            "<td>" +
            "<input class=\"v-input-table\" placeholder=\"IPv4 Address\" type=\"text\" size=\"50\" name=\"ipv4address[]\">" +
            "</td>" +
            "</tr>";

        if ($("tr.ipv4").length) {
            $("tr.ipv4").last().after(markup);
        } else {
            $("#ipv4").last().after(markup);
        }
        $(".ipv4.remove").show();
    });

    $(".ipv6.add").on("click", function () {
        var markup =
            "<tr class=\"ipv6\">" +
            "<td><strong class=\"v-table-input-label\">IPv6 Address</strong></td>" +
            "<td>" +
            "<input class=\"v-input-table\" placeholder=\"IPv6 Address\" type=\"text\" size=\"50\" name=\"ipv6address[]\">" +
            "</td>" +
            "</tr>";
        if ($("tr.ipv6").length) {
            $("tr.ipv6").last().after(markup);
        } else {
            $("#ipv6").last().after(markup);
        }
        $(".ipv6.remove").show();
    });

    $(".ccEmail.add").on("click", function () {
        var markup =
            "<tr class=\"copyEmail\">" +
            "<td><strong class=\"v-table-input-label\">CC:</strong></td>" +
            "<td>" +
            "<input class=\"v-input-table\" type=\"email\" name=\"emailCopy[]\">" +
            "</td>" +
            "</tr>";

        if ($("tr.copyEmail").length <= 4) {
            $("tr.copyEmail").last().after(markup);
        } else {
            $("#ccEmail").last().after(markup);
        }
        $(".ccEmail.remove").show();
    });

    $(".ccEmail.remove").on("click", function () {
        $("tr.copyEmail").last().remove();
        if (!$("tr.copyEmail").length) {
            $(".ccEmail.remove").hide();
        }
    });

    $(".ipv4.remove").on("click", function () {
        $("tr.ipv4").last().remove();
        if (!$("tr.ipv4").length) {
            $(".ipv4.remove").hide();
        }
    });

    $(".ipv6.remove").on("click", function () {
        $("tr.ipv6").last().remove();
        if (!$("tr.ipv6").length) {
            $(".ipv6.remove").hide();
        }
    });

    /*$(".stacker.add").on("click", function() {
        console.log('Made It Here');
        var markup =
               '<tr class="cnd">' +
                   '<td><strong class="v-table-input-label">Common Name (Domain)</strong></td>' +
                '<td class="input-container">' +
                    '<input class="v-input-table" type="text" name="domainnames[]" value="" placeholder="eg. yourdomain.com.au" />' +
                '</td>' +
            '</tr>';
        if ($('tr.cnd').length) {
            $("tr.cnd").last().after(markup);
        } else {
            $("#cnd").last().after(markup);
        }
        $('.stacker.remove').show();
    });

    $(".stacker.remove").on("click", function() {
        $("tr.cnd").last().remove();
        if (!$("tr.cnd").length) {
            $('.stacker.remove').hide();
        }
    });*/
};

function load_counters(menu) {
    menu = $(menu);
    if (menu.attr("loaded") != "1") {
        menu.attr("loaded", "1");

        if (menu.hasClass("menu-admin-domainnames")) {
            $.getJSON("/home/ajax?type=domain_name_menu_count", function (data) {
                var pending_cor = $($("a:contains(\"COR Pending Approval\")"), $(".menu-admin-domainnames").next().children());
                var pending_rego = $($("a:contains(\"Registrations Pending\")"), $(".menu-admin-domainnames").next().children());
                var active_complaints = $($("a:contains(\"Active Complaints\")"), $(".menu-admin-domainnames").next().children());
                var gtld_email = $($("a:contains(\"Obtain Registrant Email\")"), $(".menu-admin-domainnames").next().children());
                var aulockdown = $($("a:contains(\"auLOCKDOWN\")"), $(".menu-admin-domainnames").next().children());
                var transfer_past_eta = $($("a:contains(\"Transfers Past ETA\")"), $(".menu-admin-domainnames").next().children());
                var delayed_expiry = $($("a:contains(\"Expiry Notice Delayed\")"), $(".menu-admin-domainnames").next().children());
                pending_cor.html(pending_cor.html() + " (" + data["cor"] + ")");
                pending_rego.html(pending_rego.html() + " (" + data["pending_reg"] + ")");
                active_complaints.html(active_complaints.html() + " (" + data["complaint"] + ")");
                gtld_email.html(gtld_email.html() + " (" + data["gtld_email"] + ")");
                aulockdown.html(aulockdown.html() + " (" + data["aulockdowns"] + ")");
                transfer_past_eta.html(transfer_past_eta.html() + " (" + data["transferpasteta"] + ")");
                delayed_expiry.html(delayed_expiry.html() + " (" + data["exp_notice_delayed"] + ")");
            });
        } else if (menu.hasClass("menu-admin-resellers")) {
            $.getJSON("/home/ajax?type=domain_name_menu_count", function (data) {
                var applications = $($("a:contains(\"Applications\")"), $(".menu-admin-resellers").next().children());
                applications.html(applications.html() + " (" + data["applications"] + ") ");
            });
        }
    }
}

window.domains_select = function domains_select(cs) {
    if ($("input[name=copyfrom]").length > 0) {
        $("input[name=copyfrom]").autocomplete({
            source: function (request, response) {
                $.ajax({
                    url: "/home/ajax",
                    dataType: "json",
                    data: {
                        q: request.term,
                        type: "domains_json"
                    },
                    success: function (data) {
                        response($.map(data, function (item) {
                            return {
                                domainname: __highlight(item.domainname, request.term),
                                value: item.domainname,
                                real: item.real
                            };
                        }));
                    }
                });
            },
            open: function () {
                $(this).removeClass("wait");
            },
            search: function (event, ui) {
                $(this).addClass("wait");
            },
            select: function (e, c) {
                if (!c.item.real) {
                    return false;
                }
                $.getJSON("/home/ajax", {
                    type: "get_contacts",
                    domain: c.item.value,
                    contacts: cs
                }, function (data) {
                    if (data.success != false) {
                        $.each(data, function (k, v) {
                            $.each(v, function (k1, v1) {
                                $.each(v1, function (k2, v2) {
                                    if (k2 == "country") {
                                        v2 = v2.toUpperCase();
                                        if (v2.length > 0) {
                                            $("#" + k1 + "_" + k2 + " option[value=" + v2 + "]").attr("selected", "selected");
                                            $("#" + k1 + "_" + k2 + "_chosen .chosen-single span").text($("option[value=" + v2 + "]").first().text());
                                            $("#" + k1 + "_" + k2 + "_chosen").trigger("chosen:updated");
                                        }
                                    } else if (k2 == "company" && !$("#pageDomain").text().trim().endsWith(".uk")) {
                                        $("#" + k1 + "_organisation").val(v2);
                                    } else if ($("#pageDomain").text().trim().endsWith(".uk") && (k2 == "firstname" || k2 == "lastname" || k2 == "organisation")) {
                                        // do nothing
                                    } else {
                                        $("#" + k1 + "_" + k2).val(v2);
                                    }
                                });
                            });
                        });
                    }
                });
            }
        })._renderItem = function (ul, item) {
            return $("<li></li>").data("item.autocomplete", item).append($("<a></a>").html(item.domainname)).appendTo(ul);
        };
    }
};

window.searchDomainsByContacts = function searchDomainsByContacts(cs) {
    if ($("input[name=copyfrom]").length > 0) {
        $("input[name=copyfrom]").autocomplete({
            source: function (request, response) {
                $.ajax({
                    url: "/home/ajax/domain/search-domain-contacts",
                    dataType: "json",
                    data: {
                        q: request.term
                    },
                    success: function (data) {
                        response($.map(data, function (item) {
                            return {
                                domainName: item.domainName,
                                match: item.match,
                                value: item.domainName,
                                real: item.real
                            };
                        }));
                    }
                });
            },
            open: function () {
                $(this).removeClass("wait");
            },
            search: function (event, ui) {
                $(this).addClass("wait");
            },
            select: function (e, c) {
                if (!c.item.real) {
                    return false;
                }
                $.getJSON("/home/ajax", {
                    type: "get_contacts",
                    domain: c.item.value,
                    contacts: cs
                }, function (data) {
                    if (data.success != false) {
                        $.each(data, function (k, v) {
                            $.each(v, function (k1, v1) {
                                $.each(v1, function (k2, v2) {
                                    if (k2 == "country") {
                                        v2 = v2.toUpperCase();
                                        if (v2.length > 0) {
                                            $("#" + k1 + "_" + k2 + " option[value=" + v2 + "]").attr("selected", "selected");
                                            $("#" + k1 + "_" + k2 + "_chosen .chosen-single span").text($("option[value=" + v2 + "]").first().text());
                                            $("#" + k1 + "_" + k2 + "_chosen").trigger("chosen:updated");
                                        }
                                    } else if (k2 == "company" && !$("#pageDomain").text().trim().endsWith(".uk")) {
                                        $("#" + k1 + "_organisation").val(v2);
                                    } else if ($("#pageDomain").text().trim().endsWith(".uk") && (k2 == "firstname" || k2 == "lastname" || k2 == "organisation")) {
                                        // do nothing
                                    } else {
                                        $("#" + k1 + "_" + k2).val(v2);
                                    }
                                });
                            });
                        });
                    }
                });
            }
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            ul.addClass("domain-search-results-ul");

            return $("<li></li>").data("item.autocomplete", item).append(
                $("<a style=\"height: fit-content;\"></a>").html(
                    item.hasOwnProperty("match")
                        ? "<span style=\"font-size:13px;\">Domain: " + item.domainName + "</span><b style=\"font-size:13px;\">" + item.match + "</b>"
                        : "<span style=\"font-size:13px;\">" + item.domainName + "</span>"
                )
            ).appendTo(ul);
        };
    }
};

window.searchDomainsByName = function searchDomainsByName(cs) {
    if ($("input[name=copyfrom]").length > 0) {
        $("input[name=copyfrom]").autocomplete({
            source: function (request, response) {
                $.ajax({
                    url: "/home/ajax/domain/search-domains",
                    dataType: "json",
                    data: {
                        q: request.term,
                    },
                    success: function (data) {
                        response($.map(data, function (item) {
                            return {
                                domainName: __highlight(item.domainName, request.term),
                                value: item.domainName,
                                real: item.real
                            };
                        }));
                    }
                });
            },
            open: function () {
                $(this).removeClass("wait");
            },
            search: function (event, ui) {
                $(this).addClass("wait");
            },
            select: function (e, c) {
                if (!c.item.real) {
                    return false;
                }
                $.getJSON("/home/ajax", {
                    type: "get_contacts",
                    domain: c.item.value,
                    contacts: cs
                }, function (data) {
                    if (data.success != false) {
                        $.each(data, function (k, v) {
                            $.each(v, function (k1, v1) {
                                $.each(v1, function (k2, v2) {
                                    if (k2 == "country") {
                                        v2 = v2.toUpperCase();
                                        if (v2.length > 0) {
                                            $("#" + k1 + "_" + k2 + " option[value=" + v2 + "]").attr("selected", "selected");
                                            $("#" + k1 + "_" + k2 + "_chosen .chosen-single span").text($("option[value=" + v2 + "]").first().text());
                                            $("#" + k1 + "_" + k2 + "_chosen").trigger("chosen:updated");
                                        }
                                    } else if (k2 == "company" && !$("#pageDomain").text().trim().endsWith(".uk")) {
                                        $("#" + k1 + "_organisation").val(v2);
                                    } else if ($("#pageDomain").text().trim().endsWith(".uk") && (k2 == "firstname" || k2 == "lastname" || k2 == "organisation")) {
                                        // do nothing
                                    } else {
                                        $("#" + k1 + "_" + k2).val(v2);
                                    }
                                });
                            });
                        });
                    }
                });
            }
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            ul.addClass("domain-search-results-ul");

            return $("<li></li>").data("item.autocomplete", item).append(
                $("<a style=\"height:fit-content;\"></a>").html(
                    "<span style=\"font-size:13px;\">" + item.domainName + "</span>"
                )
            ).appendTo(ul);
        };
    }
};

window.isNumber = function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

window.__highlight = function __highlight(s, t) {
    var matcher = new RegExp("(" + $.ui.autocomplete.escapeRegex(t) + ")", "ig");
    return s.replace(matcher, "<strong>$1</strong>");
};

window.fileName = function fileName() {
    var inputs = document.querySelectorAll(".inputfile");
    Array.prototype.forEach.call(inputs, function (input) {
        var label = input.nextElementSibling,
            labelVal = label.innerHTML;

        input.addEventListener("change", function (e) {
            var fileName = "";
            if (this.files && this.files.length > 1)
                fileName = (this.getAttribute("data-multiple-caption") || "").replace("{count}", this.files.length);
            else
                fileName = e.target.value.split("\\").pop();

            if (fileName)
                label.querySelector("span").innerHTML = fileName;
            else
                label.innerHTML = labelVal;
        });

        input.addEventListener("focus", function () {
            input.classList.add("has-focus");
        });
        input.addEventListener("blur", function () {
            input.classList.remove("has-focus");
        });
    });
};


window.validError = function validError(target, style, time) {
    target.css(style);
    setTimeout(function () {
        validClear(target);
    }, time);
};

window.validClear = function validClear(target) {
    target.removeAttr("style");
};

window.vAlert = function vAlert(action, src, data, info) {
    /*  Src = $(this)
        data = href
        info = html/text data */

    switch (action) {

        case "disable2FA": /* account/security */
            swal({
                title: "Disable Two Factor Authentication?",
                text: "Please confirm you wish to disable two-factor authentication. Your account will only be protected by your password.",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Remove",
                confirmButtonAriaLabel: "Remove",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "regenerateTwoFactorRecovery": /* account/security */
            swal({
                title: "Regenerate Two Factor Recovery Codes?",
                text: "Please confirm you wish to regenerate two-factor recovery codes. Any current codes will be removed..",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Remove",
                confirmButtonAriaLabel: "Remove",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "deleteCustomPrice": /* admin/hosting/pricing */
            swal({
                title: "Delete Custom Pricing?",
                text: "Are you sure? This cannot be undone!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonAriaLabel: "Delete",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    window.location.href = data;
                }
            });
            break;

        case "deleteModuleFile": /* admin/modules */
            swal({
                title: "Delete File?",
                text: "Confirm you wish to delete this file",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonAriaLabel: "Delete",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "audaExportCSV": /* admin/reseller/auda_export */
            swal({
                title: "Export CSV?",
                text: "You must import this list into the auDA portal upon exporting.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Export",
                confirmButtonAriaLabel: "Export",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "deleteNote": /* admin/reseller/info || notes*/
            swal({
                title: "Delete Note?",
                text: "Are you sure you'd like to delete this note?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonAriaLabel: "Delete",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "deleteNoteRef": /* admin/reseller/info || notes*/
            swal({
                title: "Delete Note?",
                text: "Are you sure you'd like to delete this note?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonAriaLabel: "Delete",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    window.location.href = data;
                }
            });
            break;

        case "resetAccPin": /* admin/reseller/info */
            swal({
                title: "Reset Account Pin?",
                text: "Are you sure you want to reset the Account PIN for this reseller?",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Reset",
                confirmButtonAriaLabel: "Reset",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    window.location.href = data;
                }
            });
            break;

        case "resetIdentityVerification": /* admin/reseller/info */
            swal({
                title: "Reset Identity Verification Process?",
                text: "Are you sure you want to reset the Identity Verification process for this reseller? This will reset the number of verification attempts, and restrict their account until they provide a valid form of government issued identification.",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Reset",
                confirmButtonAriaLabel: "Reset",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    window.location.href = data;
                }
            });
            break;

        case "approveIdentityVerification": /* admin/reseller/info */
            swal({
                title: "Approve Identity Verification?",
                text: "Are you sure you want to approve the Identity Verification for this reseller? This will set the identity verification status to verified and remove temporary account verification restrictions.",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Approve",
                confirmButtonAriaLabel: "Approve",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    window.location.href = data;
                }
            });
            break;

        case "deleteTransaction": /* admin/reseller/info */
            swal({
                title: "Delete Transaction?",
                text: "Are you sure you'd like to delete this transaction?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonAriaLabel: "Delete",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    window.location.href = data;
                }
            });
            break;

        case "reverseTransaction": /* admin/transaction/reverse */
            swal({
                title: "Reverse Transaction?",
                text: "Are you sure you wish to add a reversal for this transaction?",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Reverse",
                confirmButtonAriaLabel: "Reverse",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "resendWelEmail": /* admin/reseller/email */
            swal({
                title: "Send Welcome Email?",
                text: "Please confirm you wish to resend the welcome email for this reseller",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Send",
                confirmButtonAriaLabel: "Send",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    window.location.href = data;
                }
            });
            break;

        case "sendICANN": /* home/ajax */
            swal({
                title: "Send ICANN Compliance Notice?",
                text: "Are you sure you want to send an ICANN Compliance Notice for this reseller?",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Send",
                confirmButtonAriaLabel: "Send",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    window.location.href = data;
                }
            });
            break;

        case "acceptApp": /* home/ajax */
            swal({
                title: "Accept Application?",
                html: "Confirm you wish to <b style=\"color:#39B54A\">accept</b> this application.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Accept",
                confirmButtonAriaLabel: "Accept",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    window.location.href = data;
                }
            });
            break;

        case "rejectApp": /* home/ajax */
            swal({
                title: "Reject Application?",
                html: "Confirm you wish to <b style=\"color:#F85359\">reject</b> this application.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Reject",
                confirmButtonAriaLabel: "Reject",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    window.location.href = data;
                }
            });
            break;

        case "deleteCategory": /* home/ajax */
            swal({
                title: "Delete Category?",
                text: "You still have domains assigned to this category. Are you sure you'd like to delete the Category?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonAriaLabel: "Delete",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "disableAcc": /* home/ajax */
            swal({
                title: "Disable Account?",
                html: "This reseller has <b>" + info + " unpaid invoices.</b> Are you sure you want to disable this account?",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Disable",
                confirmButtonAriaLabel: "Disable",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    window.location.href = data;
                }
            });
            break;

        case "disableAccManage": /* home/ajax */
            swal({
                title: "Disable Account Management?",
                html: "Are you sure you want to disable Account Management for this account?",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Disable",
                confirmButtonAriaLabel: "Disable",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    window.location.href = data;
                }
            });
            break;

        case "deleteDomain": /* domains/admin/delete*/
            swal({
                title: "Delete Domain?",
                html: "<b style='color:#F85359'>This cannot be reversed!</b> <br><br> Are you sure you'd like to delete this domain?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonAriaLabel: "Delete",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "performSync": /*  */
            swal({
                title: "Perform Sync?",
                html: "This will take around 30 seconds to complete, please be patient.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Sync",
                confirmButtonAriaLabel: "Sync",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "forceApproval": /*  */
            swal({
                title: "Force Approval?",
                html: "This is against policy to force the approval of a transfer. Please ensure you have registrant approval to continue with this request!",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Sync",
                confirmButtonAriaLabel: "Sync",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "setComplete": /*  */
            swal({
                title: "Set as Completed?",
                html: "Please ensure you are 100% correct the domain has finished transfer prior to changing the status.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "forceTrans": /*  */
            swal({
                title: "Force Approve Transfer?",
                html: "This is against policy to force the approval of a transfer. Please ensure you have registrant approval to continue with this request!",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "AckEmail": /*  */
            swal({
                title: "Acknowledge Missing Email?",
                html: "This will acknowledge the email address is missing and stop the cron from sending reminders.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "recheckScraper": /*  */
            swal({
                title: "Recheck Whois Scraper?",
                html: "This will recheck the whois output via the Whois Scraper System.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "confirmCancel": /*  */
            swal({
                title: "Confirm Cancellation?",
                html: "<b style='color:#F85359'>This cannot be reversed!</b> <br><br> Are you sure you'd like to delete this domain?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "transCancel": /*  */
            swal({
                title: "Confirm Cancellation?",
                html: "<b style='color:#F85359'>This cannot be reversed!</b> <br><br> Are you sure you'd like to cancel this transfer?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "rejectTrans": /*  */
            swal({
                title: "Reject Transfer?",
                html: "Rejecting this outbound request will cancel the domain transfer immediately and return it to normal.",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "approveTrans": /*  */
            swal({
                title: "Approve Transfer?",
                html: "Approving this outbound request will release the domain immediately and no longer manageable from this system.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "uncancelServ": /* hosting/cancel */
            swal({
                title: "Uncancel Service?",
                html: "Are you sure you wish to <b>uncancel</b> this service?",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "cancelServ": /* hosting/cancel */
            swal({
                title: "Cancel Service?",
                html: "Are you sure you wish to <b style='color:#F85359'>cancel</b> this service?",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "uncancelSub": /* subscriptions/cancel */
            swal({
                title: "Uncancel Subscription?",
                html: "Are you sure you wish to <b>uncancel</b> this subscription?",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "cancelSub": /* subscriptions/cancel */
            swal({
                title: "Cancel Subscription?",
                html: "Are you sure you wish to <b style='color:#F85359'>cancel</b> this subscription?",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "suspendSub": /* subscriptions/suspend */
            swal({
                title: "Suspend Subscription?",
                html: "Are you sure you wish to <b style='color:#F85359'>suspend</b> this subscription? Note this process may take up to 5-10 minutes.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "deleteSendAdd": /* */
            swal({
                title: "Delete Sender Address?",
                html: "Please confirm you wish to delete this sender address.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "performCancel": /* */
            swal({
                title: "Perform Cancellation?",
                html: "This will take around 30 seconds to complete, please be patient.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "cancelSSL": /* */
            swal({
                title: "Cancel SSL Certificate?",
                html: "Are you sure you want to cancel this SSL certificate?",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "cancelSslOrder": /* */
            swal({
                title: "Cancel SSL Certificate?",
                html: "Please ensure you wish to cancel this SSL for " + info + "?",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "deleteDSData": /* */
            swal({
                title: "Delete DS Data?",
                text: "Are you sure you want to delete this DS Data?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonAriaLabel: "Delete",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;

        case "initCOR": /* */
            swal({
                title: "Initiate Change of Registrant?",
                html: " Any unused registration time not contained within a full year will be <b>removed</b>, with your license period reset to <b>" + data + "</b> in addition to any current existing full years.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                }
            });
            break;
        case "recreateService":
            swal({
                title: "Are you sure you want to recreate this cPanel Account?",
                html: "Upon recreation, all data is deleted and cannot be reversed.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                    src.prop("disabled", true);
                }
            });
            break;
        case "createService":
            swal({
                title: "Are you sure you want to create this cPanel Account?",
                html: "Please ensure before proceeding any issues that may have prevented provisioning have been resolved.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                    src.prop("disabled", true);
                }
            });
            break;
        case "createMailbox":
            swal({
                title: "Are you sure you want to create this mailbox Account?",
                html: "Please ensure before proceeding any issues that may have prevented provisioning have been resolved.",
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                    src.prop("disabled", true);
                }
            });
            break;
        case "toggleIPv6":
            let message = `Upon toggling, a AAAA IPv6 record is either added
             or removed against the cPanel account for this service. <strong>This is processed immediately.</strong>`;
            swal({
                title: "Are you sure you want to do this?",
                html: message,
                type: "question",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonAriaLabel: "Confirm",
                cancelButtonText: "Cancel",
                cancelButtonAriaLabel: "Cancel"
            }).then(function (result) {
                if (result.value) {
                    src.closest("form").trigger("submit");
                    src.prop("disabled", true);
                }
            });
            break;
    }
};

window.validateEmail = function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

window.truncate = function truncate(text, num) {
    if (text.length > num) {
        return text.substring(0, num) + "...";
    } else {
        return text;
    }
};

window.generateStateOptions = function generateStateOptions(oldState) {
    const countryElement = $("#country");
    const stateElement = $("#state");

    stateElement.empty();

    stateElement.append($("<option>", {
        value: "",
        text: ""
    }));

    $.ajax({
        url: "/home/ajax",
        dataType: "json",
        data: {
            country: countryElement.val(),
            type: "get_states"
        }
    }).done(function (response) {
        $.each(response, function (key, value) {
            let stateData = {
                value: value.isoCode,
                text: value.name
            };

            if (oldState === value.isoCode) {
                stateData.selected = "selected";
            }

            stateElement.append($("<option>", stateData));
        });

        stateElement.attr("disabled", false);
        stateElement.chosen();
        stateElement.trigger("chosen:updated");
        stateElement.trigger("change");
    });
};

window.clipboard = function clipboard(element) {
    let regex = /\r|\n/;
    let temp = $("<textarea>");
    $("main").append(temp);
    temp.val($(element).html().replace(regex, "\r\n").trim()).select();
    document.execCommand("copy");
    temp.remove();
    toastr["success"]('Copied to clipboard!');
}

window.escapeHtml = function escapeHtml(text) {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
    };

    return text.replace(/[&<>"']/g, function (m) {
        return map[m];
    });
}

window.loaderIn = function loaderIn() {
    NProgress.start();
}

window.loaderOut = function loaderOut() {
    NProgress.done();
}
