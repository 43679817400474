window.accountManagerReady = function accountManagerReady() {
    let dates = [];
    for (let i=0; i <= 7; i++) {
        let newDate = new Date();
        if (i > 0) {
            newDate.setDate(newDate.getDate() + i);
        }
        if (newDate.getDay() > 0 && newDate.getDay() < 6) {
            dates.push(newDate);
        }
    };
    let today = luxon.DateTime.local().setZone("Australia/Melbourne");
    let tomorrow = today.plus({ days: 1}).toJSDate();
    today = today.toJSDate();
    function getDayName(date)
    {
        return date.toLocaleString('default', { weekday: 'long' });
    }
    function getMonthName(date)
    {
        return date.toLocaleString('default', { month: 'long' });
    }
    // https://stackoverflow.com/a/13627586/9237548
    function english_ordinal_suffix(i)
    {
        let j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + 'st';
        }
        if (j == 2 && k != 12) {
            return i + 'nd';
        }
        if (j == 3 && k != 13) {
            return i + 'rd';
        }

        return i + "th";
    }

    $.each(dates, function (index, value) {
        let timestamp = value.getTime() / 1000;
        let text = getDayName(value) + ' ' + english_ordinal_suffix(value.getDate()) + ' of ' + getMonthName(value);

        // Only show today if it's before 4PM.
        if (value.getTime() !== today.getTime() || today.getHours() < 16) {
            // Today
            if (value.getTime() === today.getTime()) {
                text = 'Today';
            }
            // Tomorrow
            if (value.getTime() === tomorrow.getTime()) {
                text = 'Tomorrow';
            }

            $('#callbackdate').append($('<option/>', {
                value: timestamp.toFixed(0),
                text: text
            }));
        }
    });
    $('#callbackdate').trigger('chosen:updated');

    $('#callbackdate').on('change', function() {
        let date = new Date($(this).val() * 1000);
        let hour = today.getHours();

        $('#callbacktime > option').each(function(index, value) {
            $(value).prop('disabled', date.getDate() === today.getDate() && $(value).val() <= hour);
        });

        $('#callbacktime').trigger('chosen:updated');
    });

    $('#callbackrequest').on("click", function() {
        $('#account-manager-callback').hide();
        $('#account-manager-calendar').show();
    });

    $('.callbackrequest__close').on("click", function () {
        $('#account-manager-callback').fadeOut('fast');
        setTimeout(function() {
            $('#request-callback').show();
            $('#request-submitted').hide();
        }, 1000);
    });

    $('.callbackrequest_calendar__close').on("click", function () {
        $('#account-manager-calendar').fadeOut('fast');
    });

    /**
     * Handle the submission on flag account manager
     */
    $('#flag-manager-btn').on("click", function () {
        $(this).prop('disabled', true);
        $('#flag-manager-btn > span').hide();
        $('#flag-manager-btn > .loader-div').show();
        let data = $('form[name=flag_manager]').serialize();
        data += window.location.search.replace('?', '&');
        $.ajax({
            type: 'post',
            url: '/home/ajax/account-management',
            data: {
                action: 'flag',
                data: data
            }
        }).done(function(response) {
            $('#flag-am').hide();
            $('#flag-manager-btn > span').show();
            $('#flag-manager-btn > .loader-div').hide()
            $('#flag-submitted').show();
            $('form[name=flag_manager]').trigger('reset');
        }).fail(function(error) {

        });
        $(this).prop('disabled', false);
    });

    /**
     * Show the "Flag Account Manager" modal on click
     */
    $('#flag-account-manager').on("click", function () {
        $('#flag-account-manager-modal').show();
    });

    /**
     * Close the modal on
     */
    $('.flag__close').on("click", function () {
        $('#flag-am').show();
        $('#flag-submitted').hide();
        $('#flag-account-manager-modal').hide();
    });

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
        var modal1 = document.getElementById("account-manager-callback");
        if (event.target == modal1) {
            modal1.style.display = "none";
        }
    };

    var chosen_search_threshold = 8;
    $('.modal select').on('chosen:showing_dropdown', function(event, chosen) {
        if (chosen.chosen.results_data.length < chosen_search_threshold) {
            hide = {
                'opacity': 0,
                'height': '0px',
                'display': 'none'
            };
            $(this).parent().find('.chosen-search').css(hide);
            $(this).parent().find('.chosen-search-input').css(hide);

        }

        if (chosen.chosen.results_data.length < chosen_search_threshold) {
            let data = chosen.chosen.results_data;
            let values = [];
            data.forEach(function(value) {
                values.push(value.text.charAt(0));
            });

            $(chosen.chosen.search_field).on('keyup', function(event) {
                let input = chosen.chosen.search_field;
                let val = input.val();

                if (!$.inArray(val.toString(), values) || val.length > 1) {
                    input.val(val.substr(-1));
                } else {
                    input.val('');
                }

                $(chosen.chosen.search_results).children().each(function(i, element) {
                    $(element).html($(element).text());
                });
            });

        }

    });
}

$(document).ready(function () {
    accountManagerReady()
});

function runIntro()
{
    $('#introModal').parent().show();
    $('#intro-btn-one').on("click", function() {
        $('#introModal').hide();
        $('#introModal-two').show();
    });

    $('#intro-btn-two').on("click", function() {
        $('#introModal-two').hide();
        $('#introModal-three').show();
    });

    $('#intro-btn-three').on("click", function() {
        $('#introModal').parent().hide();
    });
}

window.amCallback = function amCallback()
{
    $('#account-manager-callback').show();
    $('.chosen-container').css({
        'width': '100%',
        'text-align': 'left'
    });
}

window.modalClose = function modalClose(element)
{
    $(element).parent().parent().parent().parent().fadeOut('fast');
}

window.introSlide = function introSlide(slide)
{
    switch (slide) {
        case 1:
            $('#introModal').show();
            $('#introModal-two').hide();
            $('#introModal-three').hide();
            break;
        case 2:
            $('#introModal').hide();
            $('#introModal-two').show();
            $('#introModal-three').hide();
            break;
        case 3:
            $('#introModal').hide();
            $('#introModal-two').hide();
            $('#introModal-three').show();
            break;
    }
}
